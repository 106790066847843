<template>
  <img v-if="colDef.type === 'img'" width="50" :src="rowVal" />
  <span v-else-if="colDef.type === 'token'">
    <router-link :to="'/tokens/' + rowVal.id">{{rowVal.info.symbol}}</router-link>
  </span>
  <span v-else-if="colDef.type === 'tokenAmount'">
    {{ rowVal.toString() }}
  </span>
  <span style="font-size:0.7em" v-else-if="colDef.type === 'address'">
    <a :href="'https://better-call.dev/mainnet/' + rowVal">{{
      rowVal
    }}</a>
  </span>
  <span v-else-if="colDef.type === 'details'">
    <router-link :to="$route.path + '/' + row.id">{{
      rowVal
    }}</router-link>
  </span>
  <span v-else-if="colDef.type === 'bool'">
    <input v-if="rowVal !== null" type="checkbox" :checked="rowVal" disabled>
  </span>
  
  <span v-else-if="colDef.type === 'timestamp'">
    {{rowVal ? rowVal.toISOString() : ''}}
  </span>
  <span v-else :style="colDef.small ? 'font-size:0.7em' : ''">{{ rowVal }}</span>
</template>
<script>
//import { get as lodash_get } from "lodash";

export function _get(object, path, defval = null) {
    if (typeof path === "string") path = path.split(".");
    return path.reduce((xs, x) => (xs && xs[x] ? xs[x] : defval), object);
}

export default {
  name: 'FarmsColumn',
  props: {
    row: Object,
    colDef: Object,
  },
  data: function () {
    return {
      from_secs: 0,
      fee: 0,
    };
  },
  computed: {
    rowVal() {
      if (this.colDef.computed) {
        return this.colDef.computed(this.row)
      }
      return _get(this.row, this.colDef.field)
    },
  },
};
</script>