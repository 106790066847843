<template>
  <section class="container has-text-left">
    <div v-if="token">
      <b-field horizontal
        ><img
          class="has-text-right"
          width="100"
          :src="token.info.thumbnailUriHttp"
      /></b-field>
      <b-field horizontal label="ID">
        {{ token.id }}
      </b-field>
      <b-field horizontal label="Type">
        {{ token.type }}
      </b-field>
      <b-field horizontal label="Address">
        <a :href="'https://better-call.dev/mainnet/' + token.address">{{
          token.address
        }}</a>
      </b-field>
      <b-field horizontal label="FA2 ID">
        {{ token.fa2Id }}
      </b-field>
      <b-field horizontal label="Symbol">
        <b-input v-model="token.info.symbol"></b-input>
      </b-field>
      <b-field horizontal label="Name">
        <b-input v-model="token.info.name"></b-input>
      </b-field>
      <b-field
        horizontal
        label="Decimals"
        message="DANGEROUS. Know what you doing"
        type="is-warning"
      >
        <b-numberinput
          v-model="token.info.decimals"
          placeholder="0"
          min="0"
        ></b-numberinput>
      </b-field>
      <b-field horizontal label="Thumbnail URI">
        <b-input v-model="token.info.thumbnailUri"></b-input>
      </b-field>
      <b-field horizontal label="Price Tez">
        {{ token.priceTezAmount }}
      </b-field>
      <b-field horizontal label="Liquidity">
        {{ token.totalLiquidityAmount }}
      </b-field>
      <b-field horizontal label="LP">
        <b-checkbox v-model="token.lp"></b-checkbox>
      </b-field>
      <b-field horizontal label="Price Source">
        <b-select placeholder="Select a name" v-model="token.priceSource">
          <option
            v-for="option in ['Quipuswap', 'FDX']"
            :value="option"
            :key="option"
          >
            {{ option }}
          </option>
        </b-select>
      </b-field>
      <b-field horizontal label="Price Location">
        <b-input v-model="token.priceLocation"></b-input>
      </b-field>

      <b-field horizontal
        ><b-button type="is-primary" @click="updateToken"
          >Update</b-button
        ></b-field
      >
    </div>
    <div v-else-if="!loading">Failed to load token</div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="false"
    ></b-loading>
  </section>
</template>

<script>
// @ is an alias to /src
import { TokenInfo, TokenMetadata } from "@/services/FarmBucket";

export default {
  name: "Farm",
  data() {
    return {
      token: null,
      loading: false,
    };
  },
  beforeMount() {
    this.fetchToken(this.$route.params.tokenId);
  },
  methods: {
    async runInProgress(f) {
      try {
        this.loading = true;
        await f();
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          pauseOnHover: true,
          message: e.message,
          position: "is-top",
          type: "is-danger",
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchToken(id) {
      this.runInProgress(async () => {
        const token = Object.assign(
          new TokenInfo(),
          await this.$parent.apiClient.fetchToken(id)
        );
        token.info = Object.assign(new TokenMetadata(), token.info);
        this.token = token;
      });
    },
    async updateToken() {
      this.runInProgress(async () => {
        await this.$parent.apiClient.updateToken(this.token.id, this.token);
      });
    },
  },
};
</script>
