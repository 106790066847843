import Vue from 'vue'
import VueRouter from 'vue-router'
import Farms from '../views/Farms.vue'
import Farm from '../views/Farm.vue'
import Tokens from '../views/Tokens.vue'
import Token from '../views/Token.vue'


//import AuthService from '@/services/AuthService'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: Farms
  },
  {
    path: '/farms',
    name: 'Farms',
    component: Farms
  },
  {
    path: '/farms/:farmId',
    name: 'Farm',
    component: Farm
  },
  {
    path: '/tokens',
    name: 'Tokens',
    component: Tokens
  },
  {
    path: '/tokens/:tokenId',
    name: 'Token',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/Token.vue')
    component: Token
  }
]

const router = new VueRouter({
  routes
})

/*router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !AuthService.isAuthenticated()) {
    return next('/login');
  }

  next();
})*/

export default router
