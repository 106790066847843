import Vue from 'vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
Vue.use(Buefy, {
  defaultIconPack: 'fas',
})

import { TezosToolkit } from '@taquito/taquito';
import { BeaconWallet } from '@taquito/beacon-wallet';

(window as any).netName = 'mainnet';
const testnetFlag = new URLSearchParams(window.location.search).get('testnet');
if (testnetFlag && testnetFlag === "true" || (window as any).network === "testnet") {
  (window as any).netName = 'testnet';
}

enum NetworkType {
  MAINNET = 'mainnet',
  GRANADANET = 'granadanet'
}

class Deployer {
  network: {
    name: string;
    rpcUrl: string;
    type: NetworkType;
  };
  tezos: TezosToolkit;
  wallet: BeaconWallet;
  account: {address: string};

  constructor() {
    this.setNetwork((window as any).netName)
  }

  setNetwork(netName: string) {
    if(netName === 'mainnet') {
      this.network = {
        name: "mainnet",
        rpcUrl: "https://mainnet-node.madfish.solutions",
        //rpcUrl: "https://mainnet-tezos.giganode.io",        
        type: NetworkType.MAINNET
      }
    } else if (netName === 'testnet') {
      this.network = {
        name: "granadanet",
        rpcUrl: "https://granadanet.smartpy.io",
        type: NetworkType.GRANADANET
      }
    }
    this.tezos = new TezosToolkit(this.network.rpcUrl);
    const options = {
      name: 'Space Farm',
      //iconUrl: 'https://tezostaquito.io/img/favicon.png',
      preferredNetwork: this.network.type,
      /*eventHandlers: {
        PERMISSION_REQUEST_SUCCESS: {
          handler: async (data) => {
            console.log('permission data:', data);
          },
        },
      },*/
    };
    this.wallet = new BeaconWallet(options);
    this.tezos.setWalletProvider(this.wallet);
    this.account = null;
  }

  async login() {
    await this.wallet.requestPermissions({ network: { name: this.network.name, rpcUrl: this.network.rpcUrl, type: this.network.type } });
    this.account = await this.wallet.client.getActiveAccount();
    this.tezos.setWalletProvider(this.wallet);
    return this.account
  }

  logout() {
    this.account = null;
  }

  async getFarmCode(stakeType, rewardType) {
    return fetch(`farms/FarmBucketWFee-depfee-${stakeType}-${rewardType}.json`).then(r => r.json())
  }

  async deployFarm(stakeType, rewardType, farmStorage) {
    const farmCode = await this.getFarmCode(stakeType, rewardType);
    const operation = await this.tezos.wallet.originate({
      code: JSON.parse(farmCode.michelson),
      storage: farmStorage,
    }).send();
  
    const contract = await operation.contract()
    
    console.log(operation)
    console.log('Contract deployed: ' + contract.address)
    return contract;
  }
}

(window as any).deployer = new Deployer()

import App from './App.vue'
import router from './router'
import { ApiClient } from './services/ApiClient';

Vue.config.productionTip = false

Vue.prototype.deployer = (window as any).deployer;
Vue.prototype.apiClient = new ApiClient('https://api2.spacefarm.xyz/api');
Vue.prototype.tezos = (window as any).deployer.tezos;
Vue.prototype.wallet = (window as any).deployer.wallet;

new Vue({
  router,
  data: {
    deployer: (window as any).deployer,
    apiClient: new ApiClient('https://api2.spacefarm.xyz/api'),
    tezos: (window as any).deployer.tezos,
    wallet: (window as any).deployer.wallet,
  },
  computed: {
    accountAddress() {
      return (window as any).deployer.accountAddress;
    }
  },
  render: h => h(App),
}).$mount('#app')
