class AuthService {
  authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
  
    if (user && user.token) {
      return { Authorization: 'Bearer ' + user.token };
    } else {
      return {};
    }
  }

  isAuthenticated() {
    return localStorage.getItem('user') ? true : false;
  }

  getUser() {
    return localStorage.getItem('user')
  }

  logout() {
    localStorage.removeItem('user');
  }
}

export default new AuthService()