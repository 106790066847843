import { BeaconWallet } from "@taquito/beacon-wallet";
import { TezosToolkit } from "@taquito/taquito";
import BigNumber from "bignumber.js";
import { ApiClient } from "./ApiClient";
import { Common } from "./common";
import { BN_INFINITY, BN_ZERO, TokenAmount } from "./TokenAmount";

export const DEFAULT_RPS_PRECISION = new BigNumber('1000000000000000000000000');

export enum TokenType {
  FA2 = "FA2",
  FA12 = "FA12"
}

export class TokenMetadata {
  symbol: string;
  name: string;
  decimals: number;
  thumbnailUri: string;
  get thumbnailUriHttp(): string {
    try {
      const parsedUrl = new URL(this.thumbnailUri);
      if (parsedUrl.protocol === "ipfs:") {
        return `https://cloudflare-ipfs.com/ipfs${parsedUrl.pathname}`
      } else {
        return this.thumbnailUri;
      }
    } catch (e) {
      return this.thumbnailUri;
    }
  }
}

export class TokenInfo {
  id: string;
  type: TokenType
  address: string;
  fa2Id: number;
  decimals: number;
  name: string;
  symbol: string;
  thumbnailUri: string;
  info: TokenMetadata
  lp: boolean;
  priceSource: PriceSource;
  priceTez: BigNumber;
  get priceTezAmount(): TokenAmount {
    if (!this.info) {return null}
    console.log(`GET ${this.priceTez} ${this.info.decimals}`)
    return new TokenAmount(this.priceTez.shiftedBy(2  * this.decimals - 6), this.info.decimals, 12)
  }
 
}

export enum PriceSource {
  Quipuswap = "Quipuswap",
  FDX = "FDX"
}

export class BucketInfo {
  id: string;
  name: string;
  img: string;
  stakeToken: TokenInfo;
  rewardToken: TokenInfo;
  tags: string[];
  links: {title: string; url: string}[];
  deprecated: boolean;
  noclaim: boolean;
  farmToMigrate: string;
  version: number;

  totalStack: BigNumber;
  get totalStackAmount(): TokenAmount {
    return new TokenAmount(this.totalStack, this.stakeToken.decimals);
  }
  totalVirtualStack: BigNumber;
  get totalVirtualStackAmount(): TokenAmount {
    return new TokenAmount(this.totalVirtualStack, this.stakeToken.decimals);
  }

  get totalConsolidatedStack(): BigNumber {
    return this.totalStack.plus(this.totalVirtualStack);
  }
  get totalConsolidatedStackAmount(): TokenAmount {
    return new TokenAmount(this.totalConsolidatedStack, this.stakeToken.decimals);
  }

  rpsPrecision: BigNumber;
  get effectiveRpsPrecision(): BigNumber {
    return this.rpsPrecision ?? DEFAULT_RPS_PRECISION;
  }
  rewardsPerSecond: BigNumber;
  get rewardsPerSecondAmount(): TokenAmount {
    return new TokenAmount(this.rewardsPerSecond, this.rewardToken.decimals);
  }
  autoMint: boolean;
  stopped: boolean;
  rewardBalance: BigNumber;
  get rewardBalanceAmount(): TokenAmount {
    return this.rewardBalance ? new TokenAmount(this.rewardBalance, this.rewardToken.decimals) : null;
  }
  rewardsPerStake: BigNumber;
  totalRewardsLeft: BigNumber;
  lastUpdateTime: Date;
  feesCfg: {fee: number; fromSecs: number}
  depositFeesCfg: {fee: number; fromSecs: number}
  order: number;

  userPosition?: UserPosition;

  protected reward2StakeExchangeRate(): BigNumber {
    if (!this.rewardToken.priceTez || this.rewardToken.priceTez.isZero()) {
      return BN_ZERO;
    }
    if (!this.stakeToken.priceTez || (!this.stakeToken.priceTez.isNaN() && !this.stakeToken.priceTez.isFinite())) {
      return BN_INFINITY;
    }
    //return new TokenAmount(this.rewardToken.priceTez, this.rewardToken.decimals).toNormalized().div(new TokenAmount(this.stakeToken.priceTez, this.stakeToken.decimals).toNormalized())
    return new TokenAmount(this.stakeToken.priceTez, this.stakeToken.decimals).toNormalized().div(new TokenAmount(this.rewardToken.priceTez, this.rewardToken.decimals).toNormalized())
  }

  // roi in tezos
  protected roiForDaysRate(days: number): BigNumber {
    const er = this.reward2StakeExchangeRate();
    if (!er.isFinite() || er.isZero()) {
      return BN_INFINITY;
    }
    const seconds = new BigNumber(60 * 60 * 24 * days);
    return seconds.multipliedBy(new TokenAmount(this.rewardsPerSecond, this.rewardToken.decimals).toNormalized())
    .div(er.multipliedBy(this.totalConsolidatedStackAmount.toNormalized()))
  }

  roiForDays(days: number): BigNumber {
    return this.roiForDaysRate(days).multipliedBy(100).decimalPlaces(2)
  }

  get apr(): BigNumber {
    return this.roiForDays(365);
  }

  get apy(): BigNumber {
    const appr = this.roiForDaysRate(365).div(365).plus(1);
    return appr.pow(365).minus(1).multipliedBy(100).decimalPlaces(2);
  }

  get endDate(): Date {
    let endDateUnix = null;
    const lastUpdateTimeBn = new BigNumber(new Date(this.lastUpdateTime).getTime() / 1000).integerValue(BigNumber.ROUND_DOWN);
    if (!this.autoMint && this.rewardsPerSecond.isGreaterThan(BN_ZERO)) {
      endDateUnix = this.rewardBalance
      .plus(lastUpdateTimeBn.multipliedBy(this.rewardsPerSecond))
      .minus(this.totalRewardsLeft)
      .div(this.rewardsPerSecond);
    }

    //FIXME: might be wrong
    
    return endDateUnix !== null ? new Date(endDateUnix.toNumber() * 1000) : null;
  }
}

interface UserPosition {
  userId: string;
  farmId: string;
  stack: BigNumber;
  virtualStack: BigNumber;
  rewards: BigNumber;
  firstStake: Date;
  lastRewardsPerStake: BigNumber;
  upline: string;
}

export class FarmBucket extends BucketInfo {
  apiClient: ApiClient;
  tezos: TezosToolkit;
  wallet: BeaconWallet;
  currentAccountAddress: () => string;

  get userStackAmount(): TokenAmount {
    if(!this.userPosition) {
      return new TokenAmount(BN_ZERO, this.stakeToken.decimals)
    }
    return new TokenAmount(this.userPosition.stack, this.stakeToken.decimals);
  }

  constructor(config: any) {
    super();
    this.initFromConfig(config)
  }

  initFromConfig(config: any) {
    Object.assign(this, config)
    if (!config.userPosition) {
      this.userPosition = null;
    }
  }

  async updateSelf() {
    const farmBucket = await this.apiClient.fetchFarm(this.id, await this.currentAccountAddress())
    this.initFromConfig(farmBucket);
  }

  get farmPercentNumber(): number {
    if (this.userPosition) {
      return this.userPosition.stack.div(this.totalConsolidatedStack).multipliedBy(100).decimalPlaces(9).toNumber();
    } else {
      return 0;
    }
  }

  rewardsPerStaked(staked: number, days: number): TokenAmount {
    return TokenAmount.fromNormalized(new TokenAmount(this.rewardsPerSecond.multipliedBy(60 * 60 * 24 * days), this.rewardToken.decimals).toNormalized().div(this.totalConsolidatedStackAmount.toNormalized()), this.rewardToken.decimals);
  }

  get tag(): string {
    if (this.stakeToken.lp) {
      if (this.stakeToken.priceSource === PriceSource.Quipuswap) {
        return "Quipu LP"
      } else if(this.stakeToken.priceSource === PriceSource.FDX) {
        return "FlameDEX LP"
      }
    }
    return "";
  }

  private _calcRewardsV4() {
    const now = new BigNumber(new Date().getTime() / 1000).integerValue(BigNumber.ROUND_DOWN);
   
    const state = {
      last_update_time: new BigNumber(new Date(this.lastUpdateTime).getTime() / 1000).integerValue(BigNumber.ROUND_DOWN),
      reward_amount_per_sec: this.rewardsPerSecond,
      reward_per_stake: this.rewardsPerStake,
      consolidated_stack: this.totalConsolidatedStack,
      reward_balance: this.rewardBalance,
      total_rewards_left: this.totalRewardsLeft,
      stopped: this.stopped,
      automint: this.autoMint,
      rps_precision: DEFAULT_RPS_PRECISION
    }
    console.log(state);
    console.log({ rps_precision: state.rps_precision.toString(), trl: state.total_rewards_left.toString(), rps: state.reward_per_stake.toString() })
    console.log(`NOW: ${now.toString()}`)
    console.log(`LUT: ${state.last_update_time.toString()}`)

    if (!state.consolidated_stack.isZero()) {
      let eff_rewards_from_last_update = state.stopped ? new BigNumber(0) : now.minus(state.last_update_time).multipliedBy(state.reward_amount_per_sec);
      if (!state.automint && (eff_rewards_from_last_update.plus(state.total_rewards_left).isGreaterThan(state.reward_balance))) {
        eff_rewards_from_last_update = state.reward_balance.minus(state.total_rewards_left);
        state.stopped = true;
      }
      state.reward_per_stake = state.reward_per_stake.plus(eff_rewards_from_last_update.multipliedBy(state.rps_precision).div(state.consolidated_stack));
      state.total_rewards_left = state.total_rewards_left.plus(eff_rewards_from_last_update);
    }

    console.log({
      trs: state.total_rewards_left.toString(),
      rps: state.reward_per_stake.toString(),
      accConStake: this.userPosition.stack.plus(this.userPosition.virtualStack).toString(),
      accRPS: state.reward_per_stake.minus(this.userPosition.lastRewardsPerStake).toString()
    })

    const reward_increse = this.userPosition.stack.plus(this.userPosition.virtualStack).multipliedBy(state.reward_per_stake.minus(this.userPosition.lastRewardsPerStake)).div(state.rps_precision);
    return this.userPosition.rewards.plus(reward_increse);
  }

  private _calcRewardsOld() {
    console.log(`Calc rewards old`)
    const now = new BigNumber(new Date().getTime() / 1000).integerValue(BigNumber.ROUND_DOWN);

    const lastUpdateTimeUnixSec = new BigNumber(new Date(this.lastUpdateTime).getTime() / 1000).integerValue(BigNumber.ROUND_DOWN);
    console.log(`LUT = ${lastUpdateTimeUnixSec} ${this.lastUpdateTime}`)
    let rps = this.rewardsPerStake;
    if (!this.totalConsolidatedStack.isZero()) {
      const rpsIncrease = now.minus(lastUpdateTimeUnixSec).multipliedBy(this.rewardsPerSecond).multipliedBy(this.effectiveRpsPrecision).dividedBy(this.totalConsolidatedStack);
      rps = this.rewardsPerStake.plus(rpsIncrease);
    }
    console.log(`RPS = ${rps}`)
    const rewardIncrease = this.userPosition.stack.plus(this.userPosition.virtualStack)
    .multipliedBy(rps.minus(this.userPosition.lastRewardsPerStake))
    .div(this.effectiveRpsPrecision)
    return this.userPosition.rewards.plus(rewardIncrease);
  }


  rewards(): BigNumber {
    console.log(`Calc rewards: ${JSON.stringify(this.userPosition)}, version = ${this.version}`)
    if (!this.userPosition) {
      return BN_ZERO;
    }
    if (this.version && this.version >= 4) {
      return this._calcRewardsV4()
    } else {
      return this._calcRewardsOld()
    }
  }

  rewardsAmount(): TokenAmount {
    return new TokenAmount(this.rewards(), this.rewardToken.decimals)
  }

  async getTokenBalanceAndOperator(contract: string) {
    return {
      balance: 0,
      operator: false
    }
  }

  private _callFa12Approve(token_addr: string, amount: string, contract?: string) {
    return {
      kind: "transaction",
      destination: token_addr,
      amount: "0",
      parameters: {
        entrypoint: "approve",
        value: {
          "prim": "Pair",
          "args": [
            {
              "string": contract ?? this.id
            },
            {
              "int": "" + amount
            }
          ]
        }
      }
    }
  }

  async stake(amount: BigNumber, address: string, upline: string) {
    console.log(`Stake ${amount.toString()} for ${address} and upline=${upline}`);
    //const stakeBalanceInfo = this.stakeToken.fa12 ? {balance: 0, operator: false} : await this.getTokenBalanceAndOperator();
    const amountStr = "" + amount.decimalPlaces(0).toString();
    const ops = [];
    if (this.stakeToken.type === TokenType.FA12) {
      ops.push(this._callFa12Approve(this.stakeToken.address, amountStr));
    } else {
      ops.push(Common.addOperatorOp(address, this.stakeToken.address, this.stakeToken.fa2Id, this.id));
    }
    if (upline) {
      ops.push({
        kind: "transaction",
        destination: this.id,
        amount: "0",
        parameters: {
          entrypoint: "stake",
          value:
          {
            "prim": "Pair",
            "args": [
              {
                "int": amountStr
              },
              {
                "prim": "Some",
                "args": [
                  {
                    "string": upline
                  }
                ]
              }
            ]
          }
        }
      })
    } else {
      ops.push({
        kind: "transaction",
        destination: this.id,
        amount: "0",
        parameters: {
          entrypoint: "stake",
          value:
          {
            "prim":
              "Pair",
            "args":
              [
                {
                  "int":
                    amountStr
                },
                {
                  "prim":
                    "None"
                }
              ]
          }

        }
      });
    }
    return await this.wallet.sendOperations(ops)
  }

  async unstake(amount: BigNumber, address: string) {
    console.log(`Unstake ${amount.toString()} for ${address}`)
    const ops = [
      {
        kind: "transaction",
        destination: this.id,
        amount: "0",
        parameters: {
          entrypoint: "unstake",
          value:
          {
            "int":
              "" + amount
          }

        }
      }
    ]
    return await this.wallet.sendOperations(ops)
  }


  async claim(address: string) {
    console.log(`Claim for ${address}`);
    const ops = [this.createClaimOps()];
    return await this.wallet.sendOperations(ops)
  }

  createClaimOps() {
    return {
      kind: "transaction",
      destination: this.id,
      amount: "0",
      parameters: {
        entrypoint: "claim",
        value: {
          "prim": "Unit"
        }
      }
    };
  }
}