import BigNumber from 'bignumber.js';
import { gql, GraphQLClient } from 'graphql-request';
import AuthService from './AuthService';

interface FarmFilter {
  visible?: boolean;
  userId?: string;
  deprecated?: boolean;
  lp?: boolean;
  stakePriceSource: string;
}

export interface LinkInput {
  title: string;
  url: string;
}
export interface FarmInfoUpdate {
  order?: number;
  name?: string;
  img?: string;
  deprecated?: boolean;
  visible?: boolean;
  noclaim?: boolean;
  farmToMigrate?: string;
  tags?: string[]
  links?: LinkInput[]
  version?: number;
}


export interface TokenMetadata {
  symbol: string;
  name: string;
  decimals: number;
  thumbnailUri: string;
}


export interface OptionalTokenMetadata {
  symbol?: string;
  name?: string;
  decimals?: number;
  thumbnailUri?: string;
}

export interface TokenSettings {
  lp: boolean;
  priceSource: string;
  priceLocation?: string;
  info?: TokenMetadata;
}

export interface OptionalTokenSettings {
  lp?: boolean;
  priceSource?: string;
  priceLocation?: string;
  info?: TokenMetadata;
}

function _objIterate(obj: any, fun: any, names: string[], converter: any) {
  const newObj: any = {};

  Object.keys(obj).forEach((k) => {
    const v = fun(k, obj[k], names, converter)
    //if (v !== null && !(typeof v === "number" && isNaN(v)) && v !== undefined) {
    obj[k] = v;
    //}
  });
  return obj;
}

export function deeplyConvert<T>(name: string, obj: T, names: string[], converter: any): any {
  if (obj !== null && typeof obj === "string" && names.includes(name)) {
    return converter(obj);
  }
  if (Array.isArray(obj)) {
    return obj.map((v) => deeplyConvert(name, v, names, converter));
  } else if (typeof obj === "object" && obj !== null && obj !== undefined) {
    return _objIterate(obj, deeplyConvert, names, converter);
  }
  return obj;
}

export class ApiClient {
  apiUrl: string;
  graphqlClient: GraphQLClient;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
    this.graphqlClient = new GraphQLClient(apiUrl)
  }

  async fetchSpaceFarm() {
    const res = await this.graphqlClient.request(gql`
      {
        spaceFarm {
          farmsCount
          totalStackTez
        }
      }
    `)
    res.spaceFarm.totalStackTez = new BigNumber(res.spaceFarm.totalStackTez)
    return res.spaceFarm;
  }

  async fetchFarms(filter: FarmFilter) {
    const userPositionFragment = filter?.userId ? `
      userPosition(userId: "${filter.userId}") {
        stack
        virtualStack
        rewards
        firstStake
        lastRewardsPerStake
        upline
      }
    ` : '';
    const farmsRes = await this.graphqlClient.request(gql`
      query getFarms($filter: FarmFilter){
        farms(filter: $filter) {
          items {
            id
            version
            name
            img
            visible
            deprecated
            noclaim
            totalStack
            totalVirtualStack
            rewardBalance
            totalRewardsLeft
            totalFees
            totalDepositFees
            rewardsPerSecond
            rewardsPerStake
            lastUpdateTime
            autoMint
            stopped
            order
            stakeToken {
              id
              address
              lp
              priceSource
              priceTez
              info {
                symbol
                name
                decimals
              }
            }
            rewardToken {
              id
              address
              lp
              priceSource
              priceTez
              info {
                symbol
                name
                decimals
              }
            }
            ${userPositionFragment}
          }
        }
      }
    `, { filter: filter })

    let convertedFarms = deeplyConvert("", farmsRes.farms,
      ["priceTez", "rewardsPerSecond", "rewardBalance", "totalRewardsLeft", "rewardsPerStake", "totalStack", "totalVirtualStack", "totalFees", "totalDepositFees", "stack", "virtualStack", "rewards", "lastRewardsPerStake"],
      (v: any) => new BigNumber(v));

    convertedFarms = deeplyConvert("", convertedFarms, ['lastUpdateTime', 'firstStake'], (v: any) => new Date(v))

    console.log(convertedFarms)

    const resItems = convertedFarms.items.filter((i: any) => i.stakeToken.info !== undefined && i.stakeToken.info !== null && i.rewardToken.info !== undefined && i.rewardToken.info !== null);
    convertedFarms.items = resItems;
    for (const item of convertedFarms.items) {
      item.stakeToken.decimals = item.stakeToken.info.decimals
      item.rewardToken.decimals = item.rewardToken.info.decimals
    }
    return convertedFarms;
  }

  async fetchFarm(farmId: string, userId?: string) {
    const userPositionFragment = userId ? `
      userPosition(userId: "${userId}") {
        stack
        virtualStack
        rewards
        firstStake
        lastRewardsPerStake
        upline
      }
    ` : '';
    const farmRes = await this.graphqlClient.request(gql`
    query getFarm($id: String!){
      farm(id: $id) {
        id
        version
        visible
        order
        name
        img
        deprecated
        noclaim
        version
        totalStack
        totalVirtualStack
        lastUpdateTime
        rewardBalance
        totalRewardsLeft
        totalFees
        totalDepositFees
        feesCfg {
          fee
          fromSecs
        }
        depositFeesCfg {
          fee
          fromSecs
        }
        rewardsPerSecond
        rewardsPerStake
        autoMint
        stopped
        tags
        links {
          title
          url
        }
        stakeToken {
          id
          type
          address
          fa2Id
          lp
          priceSource
          priceTez
          info {
            symbol
            name
            decimals
          }
        }
        rewardToken {
          id
          type
          address
          fa2Id
          lp
          priceSource
          priceTez
          info {
            symbol
            name
            decimals
          }
        }
        ${userPositionFragment}
      }
    }
  `, { id: farmId });

    farmRes.farm.stakeToken.decimals = farmRes.farm.stakeToken.info.decimals
    farmRes.farm.rewardToken.decimals = farmRes.farm.rewardToken.info.decimals

    let convertedFarm = deeplyConvert("", farmRes.farm,
      ["priceTez", "rewardsPerSecond", "rewardBalance", "totalRewardsLeft", "rewardsPerStake", "totalStack", "totalVirtualStack", "totalFees", "totalDepositFees", "stack", "virtualStack", "rewards", "lastRewardsPerStake"],
      (v: any) => new BigNumber(v));

    convertedFarm = deeplyConvert("", convertedFarm, ['lastUpdateTime', 'firstStake'], (v: any) => new Date(v))

    return convertedFarm;
  }

  async login(user: { username: string, password: string }): Promise<string> {
    const token = await this.graphqlClient.request(gql`
      mutation Login($username: String!, $password: String!) {
        login(username: $username, password: $password)
      }
    `, user);
    return token.login;
  }

  async updateFarm(farmId: string, farm: FarmInfoUpdate) {
    const farmRes = await this.graphqlClient.request(gql`
    mutation updateFarm($id: String!, $info: FarmInfoUpdate!){
      updateFarmInfo(id: $id, info: $info) {
        name
        deprecated
        noclaim
        visible
      }
    }
    `, {
      id: farmId, info: {
        order: farm.order,
        name: farm.name,
        img: farm.img,
        deprecated: farm.deprecated,
        visible: farm.visible,
        noclaim: farm.noclaim,
        farmToMigrate: farm.farmToMigrate,
        tags: farm.tags,
        links: farm.links,
        version: farm.version
      }
    }, {...AuthService.authHeader()});
  }

  async fetchTokens() {
    const tokensRes = await this.graphqlClient.request(gql`
    {
      tokens {
        items {
          id
          type
          address
          fa2Id
          totalLiquidity
          priceTez
          priceSource
          priceLocation
          lp
          info {
            symbol
            name
            decimals
            thumbnailUri
          }
        }
      }
    }
    `);
    const convertedTokens = deeplyConvert("", tokensRes.tokens,
      ["priceTez", "totalLiquidity"],
      (v: any) => new BigNumber(v));
    convertedTokens.items.forEach((v) => {
      v.decimals = v.info?.decimals
    })
    return convertedTokens;
  }

  async fetchToken(id: string) {
    const tokenRes = await this.graphqlClient.request(gql`
    query fetchToken($id: String!){
      token(id: $id) {
        
          id
          type
          address
          fa2Id
          totalLiquidity
          priceTez
          priceSource
          priceLocation
          lp
          info {
            symbol
            name
            decimals
            thumbnailUri
          }
        
      }
    }
    `, { id: id });
    const convertedToken = deeplyConvert("", tokenRes.token,
      ["priceTez", "totalLiquidity"],
      (v: any) => new BigNumber(v));
    convertedToken.decimals = convertedToken.info?.decimals
    return convertedToken;
  }

  async updateToken(tokenId: string, settings: OptionalTokenSettings) {
    const tokenRes = await this.graphqlClient.request(gql`
    mutation updateToken($id: String!, $settings: OptionalTokenSettings!){
      updateToken(id: $id, settings: $settings) {
        id
      }
    }
    `, {
      id: tokenId, settings: {
        lp: settings.lp,
        priceSource: settings.priceSource,
        priceLocation: settings.priceLocation,
        info: {
          symbol: settings.info.symbol,
          name: settings.info.name,
          decimals: settings.info.decimals,
          thumbnailUri: settings.info.thumbnailUri
        }
      } as TokenSettings
    }, {...AuthService.authHeader()});
  }
}