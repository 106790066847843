<template>
  <div class="container.is-fullhd" id="app">
    <b-navbar v-if="isAuthenticated">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img
            src="https://flamedefi.io/images/space.png"
            alt="Flame Defi admin"
          />
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item tag="router-link" to="/farms"> Farms </b-navbar-item>
        <b-navbar-item tag="router-link" to="/tokens"> Tokens </b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item tag="div"
          ><div class="tag" v-if="tezosAccount">
            {{ tezosAccount.address }}
          </div></b-navbar-item
        >

        <b-navbar-item tag="div">
          <div class="buttons">
            <a class="button is-light" @click="logout"> Log out </a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <section class="container" v-if="!isAuthenticated">
      <b-field label="Username">
        <b-input required v-model="username" :disabled="loading"></b-input>
      </b-field>
      <b-field label="Password">
        <b-input required type="password" v-model="password" :disabled="loading"></b-input>
      </b-field>
      <b-field label="Username">
        <b-button class="is-primary" @click="login" :loading="loading"
          >Login</b-button
        >
      </b-field>
    </section>
    <!--div><span v-if="deployer.account">{{deployer.account.address}}</span>
      <b-field>
      <b-select v-model="network">
        <option
                    v-for="net in networks"
                    :value="net"
                    :key="net">
                    {{ net}}
                </option>
      </b-select>
      </b-field>
      <b-button v-if="deployer.account" type="is-primary is-light" @click="deployer.logout()">Logout</b-button>
      <b-button v-if="!deployer.account" type="is-primary" @click="deployer.login()">Login</b-button>
      <div class="box">
      <FarmDeployer v-if="deployer.account"/>
      </div>
      </div-->
    <!--div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div-->
    <router-view v-if="isAuthenticated" />
  </div>
</template>
<script>
import AuthService from "@/services/AuthService";
export default {
  name: "Farm",
  data() {
    return {
      user: AuthService.getUser(),
      username: null,
      password: null,
      tezosAccount: null,
      loading: false,
    };
  },
  async created() {
    this.tezosAccount = await this.$parent.wallet.client.getActiveAccount();
  },
  computed: {
    isAuthenticated() {
      return this.user !== null;
    },
  },
  methods: {
    async runInProgress(f) {
      try {
        this.loading = true;
        await f();
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          pauseOnHover: true,
          message: e.message,
          position: "is-top",
          type: "is-danger",
        });
      } finally {
        this.loading = false;
      }
    },
    async logout() {
      this.runInProgress(async () => {
        AuthService.logout();
        await this.$parent.deployer.logout();
        this.user = null;
      });
    },
    async login() {
      this.runInProgress(async () => {
        const token = await this.$parent.apiClient.login({
          username: this.username,
          password: this.password,
        });
        const sv = JSON.stringify({ token });
        const acc = await this.$parent.deployer.login();
        console.log(acc);
        localStorage.setItem("user", sv);
        this.user = AuthService.getUser();
      });
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
