<template>
  <section>
    <b-table
      :data="isEmpty ? [] : farms"
      :bordered="isBordered"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      :loading="isLoading"
      :focusable="isFocusable"
      :mobile-cards="true"
      :paginated="true"
      :per-page="20"
      :default-sort="'order'"
      :default-sort-direction="'desc'"
    >
      <b-table-column
        v-for="columnDef in columnDefs"
        v-bind:key="columnDef.field"
        :field="columnDef.field"
        :label="columnDef.label"
        :width="columnDef.width"
        :sortable="columnDef.sortable"
        :searchable="columnDef.searchable"
        :td-attrs="columnTdAttrs"
        :custom-sort="
          columnDef.sort
            ? columnDef.sort(columnDef.field)
            : defaultSort(
                columnDef
              )
        "
        v-slot="props"
      >
        <FarmsColumn :colDef="columnDef" :row="farms[props.row.index]" />
      </b-table-column>

      <template #empty>
        <div class="has-text-centered">No records</div>
      </template>
    </b-table>
  </section>
</template>

<script>
// @ is an alias to /src
import { get as lodash_get } from "lodash";
import { BucketInfo } from "@/services/FarmBucket";
import FarmsColumn from "@/components/FarmsColumn.vue";

export function _get(object, path, defval = null) {
  if (typeof path === "string") path = path.split(".");
  return path.reduce((xs, x) => (xs && xs[x] ? xs[x] : defval), object);
}

export default {
  name: "Farms",
  components: {
    FarmsColumn,
  },
  data() {
    return {
      isEmpty: false,
      isBordered: false,
      isStriped: false,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      farms: [],
      columnDefs: [
        { field: "img", label: "", type: "img" },
        {
          field: "id",
          label: "ID",
          sortable: true,
          type: "address",
          searchable: true,
        },
        {
          field: "name",
          label: "Name",
          sortable: true,
          type: "details",
          searchable: true,
        },
        { field: "version", label: "Ver", sortable: true },
        {
          field: "stakeToken",
          label: "Stake",
          type: "token",
          width: "100",
          sortable: true,
          sortKey: "stakeToken.info.symbol",
          searchable: true,
        },
        {
          field: "rewardToken",
          label: "Reward",
          type: "token",
          width: "100",
          sortable: true,
          sortKey: "rewardToken.info.symbol",
          searchable: true,
        },
        {
          field: "totalStackAmount",
          label: "Total Stack",
          type: "tokenAmount",
          sort: this.tokenAmountSort,
          sortable: true,
        },
        {
          field: "rewardsPerSecondAmount",
          label: "Rewards/sec",
          type: "tokenAmount",
          sortable: true,
        },
        {
          field: "rewardBalanceAmount",
          label: "Balance",
          type: "tokenAmount",
          sortable: true,
        },
        {
          field: "apr",
          label: "Apr%",
          sortable: true,
          computed: (v) => v.apr.decimalPlaces(2).toNumber()
        },
        {
          field: "autoMint",
          label: "AMint",
          type: 'bool',
          sortable: true,
        },
        {
          field: "stopped",
          label: "Stopped",
          type: 'bool',
          sortable: true,
        },
        {
          field: "deprecated",
          label: "Depr",
          type: 'bool',
          sortable: true,
        },
        {
          field: "noclaim",
          label: "Nocl",
          type: 'bool',
          sortable: true,
        },
        {
          field: "visible",
          label: "Vis",
          type: 'bool',
          sortable: true,
        },
        {
          field: "lastUpdateTime",
          label: "LastUpdate",
          type: "timestamp",
          sortable: true,
        },
        {
          field: "order",
          label: "Order",
          sortable: true,
        },
      ],
    };
  },
  created() {
    this.fetchFarms();
  },
  methods: {
    async fetchFarms() {
      try {
        this.farms = (await this.$parent.apiClient.fetchFarms({})).items.map(
          (f, idx) => {
            const res = Object.assign(new BucketInfo(), f);
            res.index = idx;
            return res;
          }
        );
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          pauseOnHover: true,
          message: e.message,
          position: "is-top",
          type: "is-danger",
        });
      }
    },
    lodash_get(obj, prop) {
      return lodash_get(obj, prop);
    },
    defaultSort(columnDef) {
      if (columnDef.computed) {
        return (a, b, isAsc) => {
          if (isAsc) {
            const aVal = columnDef.computed(a);
            const bVal = columnDef.computed(b);
            return aVal == bVal ? 0 : aVal < bVal ? -1 : 1;
          } else {
            const aVal = columnDef.computed(b);
            const bVal = columnDef.computed(a);
            return aVal == bVal ? 0 : aVal < bVal ? -1 : 1;
          }
        };
      }
      const field = columnDef.sortKey ? columnDef.sortKey : columnDef.field;
      return (a, b, isAsc) => {
        if (isAsc) {
          const aVal = _get(a, field);
          const bVal = _get(b, field);
          return aVal == bVal ? 0 : aVal < bVal ? -1 : 1;
        } else {
          const aVal = _get(b, field);
          const bVal = _get(a, field);
          return aVal == bVal ? 0 : aVal < bVal ? -1 : 1;
        }
      };
    },
    tokenAmountSort(field) {
      return (a, b, isAsc) => {
        if (isAsc) {
          return _get(a, field)
            .toNormalized()
            .comparedTo(_get(b, field).toNormalized());
        } else {
          return _get(b, field)
            .toNormalized()
            .comparedTo(_get(a, field).toNormalized());
        }
      };
    },
    dateThAttrs(column) {
      return column.label === "Date"
        ? {
            title: 'This title is sponsored by "th-attrs" prop',
            class: "has-text-success",
          }
        : null;
    },
    columnTdAttrs(row, column) {
      if (row.id === "Total") {
        if (column.label === "ID") {
          return {
            colspan: 4,
            class: "has-text-weight-bold",
            style: {
              "text-align": "left !important",
            },
          };
        } else if (column.label === "Gender") {
          return {
            class: "has-text-weight-semibold",
          };
        } else {
          return {
            style: { display: "none" },
          };
        }
      }
      return {
        style: { "text-align": "left"}
      };
    },
  },
};
</script>
