<template>
  <section class="container has-text-left">
    <div v-if="farm">
      <b-field horizontal
        ><img class="has-text-right" :src="farm.img"
      /></b-field>
      <b-field horizontal label="Name">
        <b-input v-model="farm.name"></b-input>
      </b-field>
      <b-field horizontal label="Order">
        <b-numberinput v-model="farm.order" placeholder="0"></b-numberinput>
      </b-field>
      <b-field horizontal label="Img">
        <b-input v-model="farm.img"></b-input>
      </b-field>
      <b-field horizontal label="Visible">
        <b-checkbox v-model="farm.visible"></b-checkbox>
      </b-field>
      <b-field horizontal label="Deprecated">
        <b-checkbox v-model="farm.deprecated"></b-checkbox>
      </b-field>
      <b-field horizontal label="Noclaim">
        <b-checkbox v-model="farm.noclaim"></b-checkbox>
      </b-field>
      <b-field horizontal label="Farm to Migrate">
        <b-input v-model="farm.farmToMigrate"></b-input>
      </b-field>
      <b-field horizontal label="Tags">
        <b-taginput
          v-model="farm.tags"
          ellipsis
          icon="label"
          placeholder="Add a tag"
          aria-close-label="Delete this tag"
        >
        </b-taginput>
      </b-field>
      <b-field
        horizontal
        label="Version"
        message="DANGEROUS. Know what you doing"
        type="is-warning"
      >
        <b-numberinput v-model="farm.version" placeholder="99"></b-numberinput>
      </b-field>
      <b-field horizontal label="Links">
        <b-table
          :data="farm.links"
          :striped="true"
          :narrowed="true"
          :hoverable="true"
          :mobile-cards="true"
        >
          <b-table-column label="" width="30">
            <template v-slot:header="">
              <b-button
                type="is-success"
                icon-left="plus"
                @click="addLink()"
              ></b-button>
            </template>
            <template v-slot="props">
              <b-button
                type="is-danger"
                icon-left="minus"
                @click="removeLink(props.index)"
              ></b-button>
            </template>
          </b-table-column>

          <b-table-column label="Title" v-slot="props">
            <b-input required v-model="props.row.title"></b-input>
          </b-table-column>
          <b-table-column label="Url" v-slot="props">
            <b-input required v-model="props.row.url"></b-input>
          </b-table-column>
        </b-table>
      </b-field>

      <b-field horizontal
        ><b-button type="is-primary" @click="updateFarm"
          >Update</b-button
        ></b-field
      >
    </div>
    <div v-else-if="!loading">Failed to load farm</div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="false"
    ></b-loading>
  </section>
</template>

<script>
// @ is an alias to /src
import { BucketInfo } from "@/services/FarmBucket";

export default {
  name: "Farm",
  data() {
    return {
      farm: null,
      loading: false
    };
  },
  beforeMount() {
    this.fetchFarm(this.$route.params.farmId);
  },
  methods: {
    async runInProgress(f) {
      try {
        this.loading = true;
        await f();
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          pauseOnHover: true,
          message: e.message,
          position: "is-top",
          type: "is-danger",
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchFarm(id) {
      this.runInProgress(async () => {
        this.farm = Object.assign(
          new BucketInfo(),
          await this.$parent.apiClient.fetchFarm(id)
        );
      });
    },
    async updateFarm() {
      this.runInProgress(async () => {
        await this.$parent.apiClient.updateFarm(this.farm.id, this.farm);
      });
    },
    addLink() {
      this.farm.links.unshift({ title: "", url: "" });
    },
    removeLink(idx) {
      this.farm.links.splice(idx, 1);
    },
  },
};
</script>
